import React from "react";
import { Link } from "react-router-dom";
import handleScroll from "../functions/menu-scroll";
import scrollToTop from "../functions/scroll";

const Footer = () => {
  return (
    <footer className="main-footer">
      <div className="footer-col footer-col-1">
        <ul className="footer-navigation">
          <li className="footer-li">
            <Link
              onClick={() => {
                handleScroll("showcase");
              }}
              to="/#showcase"
              className="footer-link"
            >
              Showcase
            </Link>
          </li>
          <li className="footer-li">
            <Link
              onClick={() => {
                handleScroll("features");
              }}
              to="/#features"
              className="footer-link"
            >
              Features
            </Link>
          </li>
          <li className="footer-li">
            <Link
              onClick={() => {
                handleScroll("mission");
              }}
              to="/#mission"
              className="footer-link"
            >
              Mission
            </Link>
          </li>
          <li className="footer-li">
            <Link to="/pricing" onClick={scrollToTop} className="footer-link">
              Pricing
            </Link>
          </li>
        </ul>
      </div>
      <div className="footer-col footer-col-2">
        <Link
          to="/#overview"
          onClick={() => {
            handleScroll("overview");
          }}
          className="footer-link"
        >
          Terms of use
        </Link>
      </div>
      <div className="footer-col footer-col-3">
        <span className="social-links-wrapper">
          <a
            href="https://www.youtube.com/@papyourapp"
            target="_blank"
            className="social-link-wrapper"
          >
            <img
              src="https://stpapplatformeastau01.blob.core.windows.net/website/footer%20assets/social-icon-1.svg"
              alt="youtube"
              width={24}
              height={22}
            />
          </a>
          <a
            href="https://es.linkedin.com/company/pap"
            target="_blank"
            className="social-link-wrapper"
          >
            <img
              src="https://stpapplatformeastau01.blob.core.windows.net/website/footer%20assets/social-icon-2.svg"
              alt="linkedin"
              width={24}
              height={25}
            />
          </a>
          <a
            href="https://www.instagram.com/planandpublish/"
            target="_blank"
            className="social-link-wrapper"
          >
            <img
              src="https://stpapplatformeastau01.blob.core.windows.net/website/footer%20assets/social-icon-3.svg"
              alt="instagram"
              width={24}
              height={25}
            />
          </a>
          <a
            href="https://www.facebook.com/people/Plan-and-Publish/61553652285885/"
            target="_blank"
            className="social-link-wrapper"
          >
            <img
              src="https://stpapplatformeastau01.blob.core.windows.net/website/footer%20assets/social-icon-4.svg"
              alt="facebook"
              width={24}
              height={25}
            />
          </a>
          <a
            href="https://x.com/PlanandPublish_"
            target="_blank"
            className="social-link-wrapper"
          >
            <img
              src="https://stpapplatformeastau01.blob.core.windows.net/website/footer%20assets/social-icon-6.svg"
              alt="twitter"
              width={24}
              height={25}
            />
          </a>
        </span>
        <Link to="/" className="footer-logo" onClick={scrollToTop}>
          <img
            width={153}
            height={76}
            src="https://stpapplatformeastau01.blob.core.windows.net/website/footer%20assets/footer-logo.svg"
            alt="plan and publish"
          />
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
