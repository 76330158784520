import React, { useEffect, useRef, useState } from "react";

import TypeFormBtn from "../form/typeform";

const ValueSection = () => {
  const video_ref = useRef(null);
  const [valued_items, set_valued_items] = useState([
    {
      id: 1,
      img: [
        "https://stpapplatformeastau01.blob.core.windows.net/website/value%20/valued-icon-1.svg",
        "https://stpapplatformeastau01.blob.core.windows.net/website/value%20/valued-icon-1-blue.svg",
        "https://stpapplatformeastau01.blob.core.windows.net/website/value%20/valued-icon-1-gray.svg",
      ],
      title: "Drag and Drop Interface",
      poster:
        "https://stpapplatformeastau01.blob.core.windows.net/website/value%20/01_drag_and_drop.png",
      content:
        "https://stpapplatformeastau01.blob.core.windows.net/website/value%20/01_drag_and_drop.mp4",
    },
    {
      id: 2,
      img: [
        "https://stpapplatformeastau01.blob.core.windows.net/website/value%20/valued-icon-2.svg",
        "https://stpapplatformeastau01.blob.core.windows.net/website/value%20/valued-icon-2-blue.svg",
        "https://stpapplatformeastau01.blob.core.windows.net/website/value%20/valued-icon-2-gray.svg",
      ],
      title: "Customizable App",
      poster:
        "https://stpapplatformeastau01.blob.core.windows.net/website/value%20/02_customizable_app.png",
      content:
        "https://stpapplatformeastau01.blob.core.windows.net/website/value%20/02_customizable_app.mp4",
    },
    {
      id: 3,
      img: [
        "https://stpapplatformeastau01.blob.core.windows.net/website/value%20/valued-icon-3.svg",
        "https://stpapplatformeastau01.blob.core.windows.net/website/value%20/valued-icon-3-blue.svg",
        "https://stpapplatformeastau01.blob.core.windows.net/website/value%20/valued-icon-3-gray.svg",
      ],
      title: "Live Preview",
      poster:
        "https://stpapplatformeastau01.blob.core.windows.net/website/value%20/03_live_preview.png",
      content:
        "https://stpapplatformeastau01.blob.core.windows.net/website/value%20/03_live_preview.mp4",
    },
    {
      id: 4,
      img: [
        "https://stpapplatformeastau01.blob.core.windows.net/website/value%20/valued-icon-4.svg",
        "https://stpapplatformeastau01.blob.core.windows.net/website/value%20/valued-icon-4-blue.svg",
        "https://stpapplatformeastau01.blob.core.windows.net/website/value%20/valued-icon-4-gray.svg",
      ],
      title: "Responsive Design",
      poster:
        "https://stpapplatformeastau01.blob.core.windows.net/website/value%20/04_responsive_app.png",
      content:
        "https://stpapplatformeastau01.blob.core.windows.net/website/value%20/04_responsive_app.mp4",
    },
    {
      id: 5,
      img: [
        "https://stpapplatformeastau01.blob.core.windows.net/website/value%20/valued-icon-5.svg",
        "https://stpapplatformeastau01.blob.core.windows.net/website/value%20/valued-icon-5-blue.svg",
        "https://stpapplatformeastau01.blob.core.windows.net/website/value%20/valued-icon-5-gray.svg",
      ],
      title: "Plan and Publish",
      poster:
        "https://stpapplatformeastau01.blob.core.windows.net/website/value%20/04_responsive_app.png",
      content:
        "https://stpapplatformeastau01.blob.core.windows.net/website/value%20/05_publish.mp4",
    },
  ]);
  const [valued_contents, set_valued_contents] = useState(1);

  useEffect(() => {
    handle_video_play();
  }, [valued_contents]);
  const handle_video_play = () => {
    const video = video_ref.current;
    if (video.readyState === 4) {
      video.pause();
      video.currentTime = 0;
      video.play();
    }
  };

  return (
    <section className="simplcity-value-section mm-width" id="features">
      <h2 className="section-title">We Value Simplicity</h2>
      <p className="section-description">
        A platform designed with you in mind. Our mission? To make app creation
        a seamless experience, no matter your level of expertise. Your unique
        vision is at the heart of what we do.
      </p>
      <TypeFormBtn />
      <div className="options-wrapper-part">
        <div className="valuedoptions-wrapper">
          {valued_items.map((vi) => (
            <div
              key={vi.id}
              className={
                vi.id === valued_contents
                  ? "active value-option-wrapper"
                  : Math.abs(vi.id - valued_contents) === 1
                  ? "value-option-wrapper semi-active"
                  : "value-option-wrapper"
              }
              onClick={() => {
                set_valued_contents(vi.id);
              }}
            >
              <img
                src={vi.id === valued_contents ? vi.img[1] : vi.img[2]}
                alt={vi.title}
                className="responsive-valued-item"
                width={39}
                height={39}
              />
              <div className="option-texts">
                <h3 className="option-title">{vi.title}</h3>
              </div>
            </div>
          ))}
        </div>
        <div className="valued-content-wrapper">
          <video
            src={valued_items[valued_contents - 1].content}
            poster={valued_items[valued_contents - 1].poster}
            autoPlay
            type="video/mp4"
            muted
            className="value-video"
            width={936}
            height={528}
            playsInline
            ref={video_ref}
          ></video>
        </div>
      </div>
    </section>
  );
};

export default ValueSection;
